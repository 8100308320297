import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/home'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/home2',
    name: 'home2',
    component: () => import('@/views/home/home2'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/home3',
    name: 'home3',
    component: () => import('@/views/home/home3'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/home3-2',
    name: 'home3',
    component: () => import('@/views/home/home3-2'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/home4',
    name: 'home4',
    component: () => import('@/views/home/home4'),
    meta: { title: 'HJ' },
  },
  {
    path: '/home5',
    name: 'home5',
    component: () => import('@/views/home/home5'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/home6',
    name: 'home6',
    component: () => import('@/views/home/home6'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/home7',
    name: 'home7',
    component: () => import('@/views/home/home7'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/home8',
    name: 'home8',
    component: () => import('@/views/home/home8'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/home9',
    name: 'home9',
    component: () => import('@/views/home/home9'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/home/privacy'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/online_service',
    name: 'online_service',
    component: () => import('@/views/home/online_service'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/protection_tatement',
    name: 'protection_tatement',
    component: () => import('@/views/home/protection_tatement'),
    meta: { title: 'TaiKang' },
  },
  {
    path: '/receive_success',
    name: 'receive_success',
    component: () => import('@/views/home/receive_success'),
    meta: { title: 'TaiKang' },
  },
]
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  routes,
})

export default router
