import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';//使用
Vue.use(Element);
// 导入全局样式表
import './assets/css/global.css'


import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
import VDispicker from 'v-distpicker'

Vue.component('v-distpicker',VDispicker)


import * as filters from './filters' // global filters
// import '@/permission' // permission control

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})


Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
