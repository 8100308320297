<template>
  <router-view></router-view>
</template>
<script>
document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
    WeixinJSBridge.call('hideOptionMenu');
});
</script>
<style lang="less">
body {
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #222;
  }
  .el-checkbox__label {
    span {
      color: #ff9350;
    }
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #ff9350;
    border-color: #ff9350;
  }
}
</style>
